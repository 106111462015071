// Packages
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

// Components
import Button from "@gray-fox-components/components/buttons/button"

// Styles
const StyledComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-block: auto;
  max-width: 432px;
  .headline {
    font-size: 24px;
    line-height: 35px;
    margin-bottom: 40px;
    strong {
      display: block;
      font-weight: 700;
      margin-bottom: 40px;
    }
  }
  &.error {
    .headline {
      strong {
        color: var(--white-and-black-6-hex);
      }
    }
  }
  &.success {
    .headline {
      strong {
        color: var(--primary-darks-5-hex);
      }
    }
  }
`

// Main Component
function FormMessage(props) {
  const successGreeting = `Thank you for reaching out!`
  const successDescription = `We’ve received your message. Someone from the team will contact you soon.`
  const errorGreeting = `Something went wrong.`
  const errorDescription = `There was an error in sending your message. Please try again later.`

  return (
    <StyledComponentContainer
      className={`${props.type === "success" ? "success" : "error"}`}
    >
      <p className="headline">
        <strong>
          {props.type === "success" ? successGreeting : errorGreeting}
        </strong>
        {props.type === "success" ? successDescription : errorDescription}
      </p>
      <Button
        text="Back"
        type="secondary"
        functions={{ onClick: () => props.goBack() }}
      />
    </StyledComponentContainer>
  )
}

// Prop Types
FormMessage.propTypes = {
  /** Function to go back to form */
  goBack: PropTypes.func.isRequired,
  type: PropTypes.oneOf(["success", "error"]).isRequired,
}

export default FormMessage
